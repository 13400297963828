import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Invoice from 'views/others/Request/Settlement/Invoice';
import Support from 'views/others/Support';
import Upload from 'views/others/Upload';
import CreatePayout from 'views/others/CreatePayout';
import User from 'views/User';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/Default')));
const Home = Loadable(lazy(() => import('views/Home')));

// pay in pay out pages

const PayIn = Loadable(lazy(() => import('views/pay-in-pay-out/PayIn')));
const ClearPayIn = Loadable(lazy(() => import('views/pay-in-pay-out/ClearPayIn')));
const Payout = Loadable(lazy(() => import('views/pay-in-pay-out/Payout')));
const Refunds = Loadable(lazy(() => import('views/pay-in-pay-out/Refunds')));

// other pages
const Reports = Loadable(lazy(() => import('views/others/Reports')));
const Settings = Loadable(lazy(() => import('views/others/Settings')));
const Settlement = Loadable(lazy(() => import('views/others/Request/Settlement')));
const PayoutRequest = Loadable(lazy(() => import('views/others/Request/Payout')));

const childrenRoutes = [
    {
        path: '/home',
        element: <Home />
    },
    {
        path: '/dashboard/default',
        element: <DashboardDefault />
    },
    {
        path: '/dashboard/user',
        element: <User />
    },
    {
        path: '/payment/payin',
        element: <PayIn />
    },
    {
        path: '/payment/clear-payin',
        element: <ClearPayIn />
    },
    {
        path: '/payment/payout',
        element: <Payout />
    },
    {
        path: '/other/support',
        element: <Support />
    },
    {
        path: 'other/reports',
        element: <Reports />
    },
    {
        path: 'other/settings',
        element: <Settings />
    },
    {
        path: 'other/settlement-request',
        element: <Settlement />
    },
    {
        path: 'other/payout-request',
        element: <PayoutRequest />
    },
    {
        path: 'invoice/:settlementId/:merchantId',
        element: <Invoice />
    },
    {
        path: 'file-upload',
        element: <Upload />
    },
    {
        path: 'create-payout',
        element: <CreatePayout />
    }
];

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: childrenRoutes
};

export default MainRoutes;
